/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Stepper
// ==============================================

.stepper {
    width: (40px + 60px + 40px);

    &:after {
        @include clearfix;
    }
}

.stepper_label {
    display: inline-block;
    margin-bottom: 0.4em;
}

.stepper_liner {
    overflow: hidden;
}


// ----------------------------------------------
// Input

.stepper_input {
    float: left;
    width: 60px;
    height: 40px;
    padding: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    font-family: $f-stack-sans;
    font-size: $f-size-xl;
    text-align: center;

    &:focus {
        border-color: $c-input-border;
    }
}


// ----------------------------------------------
// Button

.stepper_button {
    @include image-replacement;
    position: relative;
    float: left;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 1px solid black(0.15);
    border-radius: $border-radius;
    background: $c-stepper-button;
    user-select: none;

    &:hover {
        background: $c-stepper-button-hover;
    }

    &:focus,
    &:active {
        background: $c-stepper-button-active;
    }

    &:before {
        @include image-replacement-override;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        margin-top: (24px / -2);
        margin-left: (24px / -2);
    }
}

.stepper_button--down {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:before {
        @include svg (stepper-down);
    }
}

.stepper_button--up {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:before {
        @include svg (stepper-up);
    }
}


// ==============================================
// Modifier - Large
// ==============================================

.stepper--l {
    min-width: (48px + 70px + 48px);
}

.stepper--l .stepper_input {
    width: 70px;
    height: 48px;
}

.stepper--l .stepper_button {
    width: 48px;
    height: 48px;
}
