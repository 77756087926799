/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Poster Caption
// ==============================================

.poster-caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $gap;
}

// ---------------------------------------------

@media (min-width: $bp-poster-caption) {

    .poster-caption {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

}


// ==============================================
// Poster Caption - Item
// ==============================================

.poster-caption_item {
    max-width: 655px;
}

// ----------------------------------------------

@media (max-width: $bp-poster-caption - 1px) {

    .poster-caption_item {
        // Contains the textual content
        &:last-child {
            margin-top: $gutter;
        }
    }

}

// ----------------------------------------------

@media (min-width: $bp-poster-caption) {

    .poster-caption_item {
        max-width: (655px + $liner);
    }

    .poster-caption:not(.poster-caption--flip) .poster-caption_item {
        &:first-child {
            padding-right: $liner;
        }

        &:last-child {
            padding-left: $liner;
        }
    }

}


// ==============================================
// Poster Caption - Misc.
// ==============================================

// ----------------------------------------------

@media (min-width: $bp-poster-caption) {

    .poster-caption h2 {
        font-size: 28px;
    }

}


// ==============================================
// Modifier: Flip
// ==============================================

@media (min-width: $bp-poster-caption) {

    .poster-caption--flip {
        flex-direction: row-reverse;
    }

    .poster-caption--flip .poster-caption_item {
        &:first-child {
            padding-left: $liner;
        }

        &:last-child {
            padding-right: $liner;
        }
    }

}
