/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Card List
// ==============================================

.card-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.card-list_item {
    display: flex;
    width: 100%;
    padding: 0 15px 30px;
}

.card-list_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
    padding: 15px;
    border: 1px solid $c-module-border;
    border-radius: $border-radius;
    background: $c-module-background;
}

.card-list_card > *:last-child {
    margin-bottom: 0;
}

// ----------------------------------------------

@media (min-width: 500px) {

    .card-list_item {
        width: 50%;
    }

}

// ----------------------------------------------

@media print {

    .card-list_card {
        padding: (15px / 2);
    }

}



// ==============================================
// Modifier: Three
// ==============================================

@media (min-width: 800px) {

    .card-list--three .card-list_item {
        width: 33.33%;
    }

}


// ==============================================
// Modifier: Four
// ==============================================

@media (min-width: 800px) {

    .card-list--four .card-list_item {
        width: 33.33%;
    }

}

// ----------------------------------------------

@media (min-width: 1100px) {

    .card-list--four .card-list_item {
        width: 25%;
    }

}
