/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Media
// ==============================================

.media {
    display: flex;
    align-items: flex-start;
}

.media_item {
    margin-right: 15px;
}

.media_body {
    flex: 1;
}


// ==============================================
// Modifier - Center
// ==============================================

.media--center {
    align-items: center;
}


// ==============================================
// Modifier - Flip
// ==============================================

.media--flip {
    flex-direction: row-reverse;
}

.media--flip .media_item {
    margin-right: 0;
    margin-left: 15px;
}
