/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Story
// ==============================================

//.story {}

// ---------------------------------------------

@media (min-width: 700px) {

    .story {
        column-count: 2;
        column-gap: 40px;
        margin-bottom: $gap;
    }

}

// ---------------------------------------------

@media (min-width: 900px) {

    .story {
        column-count: 3;
    }

}
