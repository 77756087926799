/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Rating Table
// ==============================================

.rating-table {
    margin-top: 15px;
    margin-bottom: 15px;
}

.rating-table th {
    padding-right: 1em;
    font-weight: normal;
    font-style: italic;
}

.rating-table .rating-box {
    margin-bottom: 0;
}
