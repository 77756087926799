/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Container
// ==============================================

.container {
    max-width: ($trim-wide + $max-content-width + $trim-wide);
    margin: 0 auto;
    padding: 0 $trim;

    &:after {
        @include clearfix;
    }
}

// ----------------------------------------------

@media (min-width: 500px) {

    .container {
        padding: 0 $trim-mid;
    }

}

// ----------------------------------------------

@media (min-width: 900px) {

    .container {
        padding: 0 $trim-wide;
    }

}


// ==============================================
// Modifiers
// ==============================================

.container--block {
    max-width: none;
}
