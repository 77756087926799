/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Availability
// ==============================================

.availability {
    font-size: $f-size-xxs;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.availability.in-stock {
    color: $c-success;
}

.availability.out-of-stock {
    color: $c-danger;
}
