/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Function - White
// ==============================================

/**
 * @param {number} opacity - 0 to 1.0
 */
@function white ($opacity) {
    @return rgba(255, 255, 255, $opacity);
}
