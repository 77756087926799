/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Remove Link
// ==============================================

.remove-link {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    color: $c-text-subtle;
    font-size: $f-size-xs;

    &:hover {
        color: $c-danger;
    }

    &:before {
        @include svg (remove-link);
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        width: 15px;
        height: 15px;
    }

    &:hover:before  {
        @include svg (remove-link-hover);
    }
}
