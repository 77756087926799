/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Tag List
// ==============================================

.tag-list {
    margin-top: -5px;
    margin-bottom: $margin-bottom;
    margin-left: -5px;

    &:after {
        @include clearfix;
    }
}

.tag-list_item {
    float: left;
    margin-top: 5px;
    margin-left: 5px;
}

.tag-list_button {
    text-transform: none;
}

//.tag-list_count {}
