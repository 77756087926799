/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Typeahead - Button
// ==============================================

.typeahead_button {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: (30px / -2);
}


// ==============================================
// Typeahead - Link
// ==============================================

// Asset is 1.5x oversized
.typeahead_link .product-image {
    width: 60px;
    height: 60px;
}

.typeahead_link .product-name {
    margin-bottom: 5px;
    line-height: 1.3;
}


// ----------------------------------------------
// Modifier: Has Button

.typeahead_link--has-button {
    padding-right: (15px + 85px + 15px); // Magic number. Space for "add to cart" button.
}
