/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Badge
// ==============================================

.badge {
    display: inline-block;
    padding: 0 5px;
    border-radius: 2px;
    background: $c-button-subtle;
    color: #FFF;
    font-size: $f-size-xxs;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
}


// ----------------------------------------------
// Modifier: Color

// Products
.badge--new  { background: $c-badge-new; }
.badge--sale { background: $c-badge-sale; }

// Orders
.badge--cancelled  { background: $c-badge-cancelled; }
.badge--closed     { background: $c-badge-closed; }
.badge--complete   { background: $c-badge-complete; }
.badge--pending    { background: $c-badge-pending; }
.badge--processing { background: $c-badge-processing; }
