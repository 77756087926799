/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Box Group
// ==============================================

.box-group {
    margin-bottom: $gap;
}

.box-group .box {
    margin-bottom: 20px;
}
