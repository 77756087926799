/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Global Layout
// ==============================================

body {
    background: $c-site-background;
    -webkit-overflow-scrolling: touch; // Use inertia when scrolling (iOS turns off with "overflow-x: hidden" for some reason)
}


// ==============================================
// Site Wrap
// ==============================================

//.site-wrap {}
