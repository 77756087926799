/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Send Friend Recipient
// ==============================================

.send-friend-recipient {
    margin-top: $margin-bottom;
    padding: 15px;
    border: 1px solid $c-module-border;
    background: $c-module-background;
}
