/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Color Libary
// ==============================================

/*
The color library provides a full spectrum of hues and shades with consistent tone.
Gradual color stops are useful for defining UI contrast levels and states.

Based on Open Color (1.4.0)

Reference: https://yeun.github.io/open-color/asset/images/open-color.svg
Website:   https://yeun.github.io/open-color/
Source:    https://github.com/yeun/open-color
*/


// ==============================================
// Colors
// ==============================================

// Gray

$c-library-gray-0: #f8f9fa;
$c-library-gray-1: #f1f3f5;
$c-library-gray-2: #e9ecef;
$c-library-gray-3: #dee2e6;
$c-library-gray-4: #ced4da;
$c-library-gray-5: #adb5bd;
$c-library-gray-6: #868e96;
$c-library-gray-7: #495057;
$c-library-gray-8: #343a40;
$c-library-gray-9: #212529;

// Red

$c-library-red-0: #fff5f5;
$c-library-red-1: #ffe3e3;
$c-library-red-2: #ffc9c9;
$c-library-red-3: #ffa8a8;
$c-library-red-4: #ff8787;
$c-library-red-5: #ff6b6b;
$c-library-red-6: #fa5252;
$c-library-red-7: #f03e3e;
$c-library-red-8: #e03131;
$c-library-red-9: #c92a2a;

// Pink

$c-library-pink-0: #fff0f6;
$c-library-pink-1: #ffdeeb;
$c-library-pink-2: #fcc2d7;
$c-library-pink-3: #faa2c1;
$c-library-pink-4: #f783ac;
$c-library-pink-5: #f06595;
$c-library-pink-6: #e64980;
$c-library-pink-7: #d6336c;
$c-library-pink-8: #c2255c;
$c-library-pink-9: #a61e4d;

// Grape

$c-library-grape-0: #f8f0fc;
$c-library-grape-1: #f3d9fa;
$c-library-grape-2: #eebefa;
$c-library-grape-3: #e599f7;
$c-library-grape-4: #da77f2;
$c-library-grape-5: #cc5de8;
$c-library-grape-6: #be4bdb;
$c-library-grape-7: #ae3ec9;
$c-library-grape-8: #9c36b5;
$c-library-grape-9: #862e9c;

// Violet

$c-library-violet-0: #f3f0ff;
$c-library-violet-1: #e5dbff;
$c-library-violet-2: #d0bfff;
$c-library-violet-3: #b197fc;
$c-library-violet-4: #9775fa;
$c-library-violet-5: #845ef7;
$c-library-violet-6: #7950f2;
$c-library-violet-7: #7048e8;
$c-library-violet-8: #6741d9;
$c-library-violet-9: #5f3dc4;

// Indigo

$c-library-indigo-0: #edf2ff;
$c-library-indigo-1: #dbe4ff;
$c-library-indigo-2: #bac8ff;
$c-library-indigo-3: #91a7ff;
$c-library-indigo-4: #748ffc;
$c-library-indigo-5: #5c7cfa;
$c-library-indigo-6: #4c6ef5;
$c-library-indigo-7: #4263eb;
$c-library-indigo-8: #3b5bdb;
$c-library-indigo-9: #364fc7;

// Blue

$c-library-blue-0: #e8f7ff;
$c-library-blue-1: #ccedff;
$c-library-blue-2: #a3daff;
$c-library-blue-3: #72c3fc;
$c-library-blue-4: #4dadf7;
$c-library-blue-5: #329af0;
$c-library-blue-6: #228ae6;
$c-library-blue-7: #1c7cd6;
$c-library-blue-8: #1b6ec2;
$c-library-blue-9: #1862ab;

// Cyan

$c-library-cyan-0: #e3fafc;
$c-library-cyan-1: #c5f6fa;
$c-library-cyan-2: #99e9f2;
$c-library-cyan-3: #66d9e8;
$c-library-cyan-4: #3bc9db;
$c-library-cyan-5: #22b8cf;
$c-library-cyan-6: #15aabf;
$c-library-cyan-7: #1098ad;
$c-library-cyan-8: #0c8599;
$c-library-cyan-9: #0b7285;

// Teal

$c-library-teal-0: #e6fcf5;
$c-library-teal-1: #c3fae8;
$c-library-teal-2: #96f2d7;
$c-library-teal-3: #63e6be;
$c-library-teal-4: #38d9a9;
$c-library-teal-5: #20c997;
$c-library-teal-6: #12b886;
$c-library-teal-7: #0ca678;
$c-library-teal-8: #099268;
$c-library-teal-9: #087f5b;

// Green

$c-library-green-0: #ebfbee;
$c-library-green-1: #d3f9d8;
$c-library-green-2: #b2f2bb;
$c-library-green-3: #8ce99a;
$c-library-green-4: #69db7c;
$c-library-green-5: #51cf66;
$c-library-green-6: #40c057;
$c-library-green-7: #37b24d;
$c-library-green-8: #2f9e44;
$c-library-green-9: #2b8a3e;

// Lime

$c-library-lime-0: #f4fce3;
$c-library-lime-1: #e9fac8;
$c-library-lime-2: #d8f5a2;
$c-library-lime-3: #c0eb75;
$c-library-lime-4: #a9e34b;
$c-library-lime-5: #94d82d;
$c-library-lime-6: #82c91e;
$c-library-lime-7: #74b816;
$c-library-lime-8: #66a80f;
$c-library-lime-9: #5c940d;

// Yellow

$c-library-yellow-0: #fff9db;
$c-library-yellow-1: #fff3bf;
$c-library-yellow-2: #ffec99;
$c-library-yellow-3: #ffe066;
$c-library-yellow-4: #ffd43b;
$c-library-yellow-5: #fcc419;
$c-library-yellow-6: #fab005;
$c-library-yellow-7: #f59f00;
$c-library-yellow-8: #f08c00;
$c-library-yellow-9: #e67700;

// Orange

$c-library-orange-0: #fff4e6;
$c-library-orange-1: #ffe8cc;
$c-library-orange-2: #ffd8a8;
$c-library-orange-3: #ffc078;
$c-library-orange-4: #ffa94d;
$c-library-orange-5: #ff922b;
$c-library-orange-6: #fd7e14;
$c-library-orange-7: #f76707;
$c-library-orange-8: #e8590c;
$c-library-orange-9: #d9480f;
