/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Lead
// ==============================================

.lead {
    font-size: 16px;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .lead {
        font-size: 21px;
    }

}
