/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Button Group
// ==============================================

.button-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top:  -0.8rem;
    margin-left: -0.8rem;
}

.button-group > * {
    margin-top:  0.8rem;
    margin-left: 0.8rem;
}


// ==============================================
// Button Group - Label
// ==============================================

.button-group_label {
    white-space: nowrap;
}
