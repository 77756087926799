/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Popup
// ==============================================

.page-popup {
    padding: 20px;
    background: #FFF;
}

.page-popup h1 {
    margin: 0 0 0.5em;
    font-size: 24px;
}
