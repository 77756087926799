/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Address List
// ==============================================

//.address-list {}

.address-list_entry {
    margin-bottom: 0;
}

.address-list_actions {
    margin-top: 1em;
}
