/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Price As Configured
// ==============================================

.price-as-configured .price-label {
    color: $c-text-subtle;
}


// ==============================================
// Price Box
// ==============================================

.price-box p {
    margin-bottom: 0;
}


// ----------------------------------------------
// From + To

//.price-box .price-from {}
//.price-box .price-to {}


// ----------------------------------------------
// Minimal

//.price-box .minimal-price {}

.price-box .minimal-price-link {
    color: $c-orange;
    font-size: $f-size-xs;
    text-transform: uppercase;
}


// ----------------------------------------------
// Old

.price-box .old-price {
    color: $c-text-subtle;
}

.price-box .old-price .price-label {
    display: none;
}

.price-box .old-price .price {
    text-decoration: line-through;
}


// ----------------------------------------------
// Regular

.price-box .regular-price {
    display: block; // Because Magento renders a <span>
}

//.price-box .regular-price .price-label {}
//.price-box .regular-price .price {}


// ----------------------------------------------
// Special

.price-box .special-price {
    color: $c-success;
}

.price-box .special-price .price-label {
    display: none;
}

//.price-box .special-price .price {}


// ==============================================
// Price Notice
// ==============================================

.price-notice {
    color: $c-text-subtle;
}
