/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Product Carousel
// ==============================================

.product-carousel {
    overflow: hidden; // Fix iOS scrolling horizontal drags page and carousel simultaneously.
    margin-bottom: ($gap * 2);
}


// ==============================================
// Product Carousel - Title
// ==============================================

.product-carousel_title {
    margin-bottom: 20px;
    padding-right: (10px + 30px + 5px + 30px + 10px);
}


// ==============================================
// Product Carousel - Wrapper
// ==============================================

.product-carousel_wrapper {
    margin: 0 -10px; // Invert margin on ".product-carousel .item"
}

.product-carousel .owl-wrapper {
    // Force each ".owl-item" to be the same height.
    // Must use !important because OwlCarousel JS sets "display: block" on init.
    display: flex !important;
}


// ==============================================
// Product Carousel - Item
// ==============================================

// Never style this element.
// It's generated by Owl Carousel's JS.
// Each ".owl-item" has equalized height because parent has "display: flex".
//.product-carousel .owl-item {}

.product-carousel .item {
    position: relative;
    display: flex; // Allow ".product-carousel .product-actions" to align using "margin-top: auto".
    flex-direction: column;
    height: 100%; // Force each to expand to the full height of parent ".owl-item".
    margin: 0 10px;
}

.product-carousel .loading:before {
    top: 50%;
    left: 50%;
    margin-top:  (24px / -2);
    margin-left: (24px / -2);
}


// ==============================================
// Product Carousel - Owl Buttons
// ==============================================

.product-carousel .owl-next,
.product-carousel .owl-prev {
    position: absolute;
    z-index: $z-carousel-nav;
    top: (-30px + -16px);
}

.product-carousel .owl-prev {
    right: (5px + 30px + 10px);
}

.product-carousel .owl-next {
    right: 10px;
}


// ==============================================
// Product Carousel - Owl Pagination
// ==============================================

.product-carousel .owl-pagination {
    width: 100%;
    text-align: center;
}

.product-carousel .owl-page {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 10px;
    background: $c-button-subtle;
    opacity: 0.33;
    cursor: pointer;

    &:hover {
        opacity: 0.66;
    }

    &.active {
        background: $c-button-subtle;
        opacity: 1;
    }
}


// ==============================================
// Elements
// ==============================================


// ----------------------------------------------
// Product Image

.product-carousel .product-image {
    width: 100%;
    margin-bottom: 10px;
}

.product-carousel .product-image img {
    width: 100%;
}


// ----------------------------------------------
// Ratings

.product-carousel .ratings {
    position: absolute;
    top: 5px;
    right: 5px;
}


// ----------------------------------------------
// Product Name

//.product-carousel .product-name {}


// ----------------------------------------------
// Price

.product-carousel .price-box {
    margin-bottom: 1em;
}


// ----------------------------------------------
// Product Actions

.product-carousel .product-actions {
    // Force each "add to cart" button to align across ".owl-item" elements.
    margin-top: auto;
}
