/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Stat List
// ==============================================

.stat-list {
    margin-bottom: $gap;
}

.stat-list .stat {
    margin-bottom: 0;
}


// ==============================================
// Modifier - Horizontal
// ==============================================

.stat-list--horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-top: -1em;
    margin-left: -1.75em;
}

.stat-list--horizontal .stat {
    margin-top: 1em;
    margin-left: 1.75em;
}


// ==============================================
// Modifier - Vertical
// ==============================================

//.stat-list--vertical {}

.stat-list--vertical .stat {
    padding: 0.75em 0;
    border-top: 1px solid $c-module-border;

    &:last-child {
        border-bottom: 1px solid $c-module-border;
    }
}
