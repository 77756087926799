/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - Focus
// ==============================================

@mixin focus {
    box-shadow: 0 0 0 3px $c-input-focus;
}

@mixin focus-error {
    box-shadow: 0 0 0 3px $c-input-focus-error;
}
