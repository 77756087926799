/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Product Grid
// ==============================================

.product-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: ($gutter * -1);
    margin-bottom: $gap;
    margin-left: ($gutter * -1);
}


// ==============================================
// Product Grid - Item
// ==============================================

.product-grid_item {
    flex: 1 0 140px;
    margin-top: $gutter;
    margin-left: $gutter;
}

// ----------------------------------------------

@media (min-width: 440px) {

    .product-grid_item {
        flex: 1 0 200px;
        max-width: 50%;
    }

}
