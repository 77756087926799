/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Category Grid
// ==============================================

.category-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: ($gutter * -1);
    margin-bottom: $gap;
    margin-left: ($gutter * -1);
}


// ==============================================
// Category Grid - Item
// ==============================================

.category-grid_item {
    flex: 1 0 170px;
    max-width: 50%;
    margin-top:  $gutter;
    margin-left: $gutter;
}

// ----------------------------------------------

@media (max-width: 420px) {

    // Force 2-columns on small screens.
    .category-grid_item {
        flex: 1 0 120px;
    }

}


// ==============================================
// Category Grid - Name
// ==============================================

.category-grid_name {
    display: block;
    margin-top: 0.75em;
    font-size: $f-size-s;
    line-height: 1.3;
    text-align: center;
}


// ==============================================
// Modifier: Fixed
// ==============================================

.category-grid--fixed {
    justify-content: center;
}

.category-grid--fixed .category-grid_item {
    flex: 0 0 170px; // Don't grow to fill area
}

// ----------------------------------------------

@media (max-width: 400px) {

    // Force 2-columns on small screens.
    .category-grid--fixed .category-grid_item {
        flex: 1 0 120px; // Grow to fill
    }

}
