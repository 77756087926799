/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Switcher
// ==============================================

.switcher {
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}

.switcher_label {
    float: left;
    line-height: 30px;
}

.switcher_button {
    position: relative;
    float: left;
    width: 60px;
    height: 30px;
    margin: 0 10px;
    border: 0;
    border-radius: 50px;
    background: $c-switcher-background;
    box-shadow: inset 0 2px 6px 0 black(0.3);
}

.switcher_knob {
    @include image-replacement;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    background: #FFF;
    box-shadow: 0 2px 2px 0 black(0.3);
    transition: all 150ms cubic-bezier(0.165, 0.84, 0.44, 1); // easeOutQuart
}

[data-switcher='right'] .switcher_knob {
    transform: translateX(60px - 4px - 22px - 4px);
}
