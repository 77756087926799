/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Global Site Notice
// ==============================================

.global-site-notice {
    padding: 15px 10px;
    background: $c-danger;
    color: #FFF;
    text-align: center;
}

.global-site-notice p {
    margin-bottom: 0;
}


// ==============================================
// Browser Notice
// ==============================================

.browser-notice {
    display: none;
}

// [shame]
// Catch "lte IE9" + IE10
// But still support older Android with legacy flexbox only
.no-flexbox.no-flexboxlegacy .browser-notice {
    display: block;
}
