/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Product Name
// ==============================================

.product-name {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
    line-height: 1.3;
}
