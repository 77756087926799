/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Checkout Agreements
// ==============================================

.checkout-agreements li {
    margin-bottom: $margin-bottom;
}

.checkout-agreements .agreement-content {
    overflow-y: auto;
    max-width: 670px;
    max-height: 125px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid $c-module-border;
    background: $c-module-background;
}
