/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - Clearfix
// ==============================================

@mixin clearfix {
    content: '';
    display: table;
    clear: both;
}
