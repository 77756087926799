/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Payment Methods
// ==============================================

.payment-methods {
    margin-bottom: 20px;
}

.payment-methods dt {
    padding: 5px 0;
}

//.payment-methods dd {}

.payment-methods .form-list {
    position: relative;
    display: inline-block;
    max-width: 100%;
    margin: 10px 0 15px;
    padding: 15px;
    border: 1px solid $c-module-border;
    border-radius: $border-radius;
    background: $c-module-background;

    &:before {
        @include triangle (up, 10px, $c-module-border);
        top: -11px;
        left: 40px;
    }

    &:after {
        @include triangle (up, 10px, $c-module-background);
        top: -10px;
        left: 40px;
    }
}

.payment-methods .form-list li:last-child {
    margin-bottom: 0;
}
