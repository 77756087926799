/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Flex Inline
// ==============================================

.flex-inline {
    display: flex;
    align-items: center;
}

.flex-inline--width-600 {
    max-width: 600px;
}


// ==============================================
// Flex Inline - Unit
// ==============================================

//.flex-inline_unit {}

.flex-inline_unit--pad {
    margin-right: 0.75em;
}

.flex-inline_unit--grow {
    flex: 1;
}
