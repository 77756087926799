/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Ratings
// ==============================================

.ratings {
    overflow: hidden;
    font-size: $f-size-xs;
}

.ratings .rating-box {
    float: left;
    margin-bottom: 0;
}

.ratings .rating-links {
    float: left;
    margin: 0 0 0 15px;
    line-height: 13px; // Height of star rating
}

.ratings .add-your-review {
    margin-left: 15px;
}


// ==============================================
// Rating Box (Stars)
// ==============================================

.rating-box {
    @include svg (rating-empty);
    width: 69px;
    height: 12px;
    margin-bottom: 0.75em;
}

.rating-box .rating {
    @include svg (rating-full);
    height: 100%;
}
