/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Catalog No Results
// ==============================================

.catalog-no-results {
    margin-bottom: $gap;
}
