/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Badge Group
// ==============================================

.badge-group {
    overflow: hidden;
    margin-top:    -5px;
    margin-bottom: $margin-bottom;
    margin-left:   -5px;
}


// ==============================================
// Badge Group - Badge
// ==============================================

.badge-group .badge {
    float: left;
    margin-top: 5px;
    margin-left: 5px;
}
