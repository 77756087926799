/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Loading
// ==============================================

.loading,
.please-wait {
    position: relative;
    padding-left: (24px + 8px);
    font-size: $f-size-s;
    line-height: 24px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background: url(../../img/build/svg/loading.svg) no-repeat;
        animation: rotate-clockwise 1.5s linear;
        animation-iteration-count: infinite;
    }
}

.button + .loading,
.button + .please-wait {
    display: inline-block;
    margin-left: 15px;
}
