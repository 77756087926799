/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - Menu
// ==============================================

@mixin menu {
    position: absolute;
    z-index: $z-menu;
    border-radius: $border-radius;
    background: $c-module-background;
    box-shadow:
        0 0 0 1px black(0.15),
        0 2px 7px black(0.35);
}
