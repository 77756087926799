/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Footer
// ==============================================

.site-footer {
    margin-top: ($gap / 2);
}


// ==============================================
// Footer Bar
// ==============================================

.footer-bar {
    min-height: $m-footer-bar-height;
    margin-bottom: $gap;
    padding: 0.65em 1em;
    border-radius: $border-radius;
    background: $c-footer-bar-background;
    color: #FFF;
    line-height: 1.3;
    text-align: center;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .footer-bar {
        font-size: $f-size-xl;
        font-weight: 300;
    }

}


// ==============================================
// Footer Top
// ==============================================

.footer-top {
    overflow: hidden;
    margin-bottom: $gap;
    border-bottom: 1px solid $c-module-border;
}

// ----------------------------------------------

@media (min-width: 700px) {

    .footer-top {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .footer-top .col:nth-child(1) {
        flex: 0 0 50%;
        max-width: 45%;
    }

    .footer-top .col:nth-child(2) {
        flex: 0 0 50%;
        max-width: 45%;
    }

    .footer-top .col:nth-child(3) {
        flex: 0 0 100%;
    }

}

// ----------------------------------------------

@media (min-width: 900px) {

    .footer-top {
        flex-wrap: nowrap;
    }

    .footer-top .col:nth-child(1) {
        flex: 0 0 32%;
        max-width: 25%;
    }

    .footer-top .col:nth-child(2) {
        flex: 0 0 32%;
        max-width: 25%;
    }

    .footer-top .col:nth-child(3) {
        flex: 0 0 36%;
    }

}


// ==============================================
// Subscribe Box
// ==============================================

.subscribe-box {
    margin-bottom: $gap;
}

.subscribe-box label {
    display: none;
}

//.subscribe-box .input-text {}
//.subscribe-box .button {}
//.subscribe-box .validation-advice {}


// ==============================================
// Social Follow
// ==============================================

.social-follow {
    margin-bottom: $gap;
}


// ==============================================
// Footer Bottom
// ==============================================

//.footer-bottom {}

// ----------------------------------------------

@media (min-width: 800px) {

    .footer-bottom {
        display: flex;
    }

    .footer-bottom .col-1 {
        flex: 0 0 70%;
    }

    .footer-bottom .col-2 {
        flex: 0 0 30%;
    }

}


// ==============================================
// Footer Nav List
// ==============================================

.footer-nav-list {
    display: flex;
    flex-wrap: wrap;
    font-size: $f-size;
}

.footer-nav-list_item {
    margin-bottom: $gap;
    padding-right: 2rem;
}

.footer-nav-list h2 {
    @include h4;
}

// ----------------------------------------------

@media (min-width: 500px) {

    .footer-nav-list_item {
        padding-right: 3rem;
    }

}


// ==============================================
// Switchers
// ==============================================

//.switchers {}

.switchers label {
    padding-right: 0.5em;
    font-size: $f-size-xs;
}

.switcher {
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 15px;
    }
}

// ----------------------------------------------

@media (min-width: 800px) {

    .switchers {
        text-align: right;
    }

}


// ==============================================
// Legal
// ==============================================

.legal {
    margin-bottom: $margin-bottom;
    font-size: $f-size-xs;
}

.legal_terms a {
    margin: 0 5px 0 0;
}

// ----------------------------------------------

@media (min-width: 800px) {

    .legal {
        text-align: right;
    }

    .legal_terms a {
        margin: 0 0 0 5px;
    }

}
