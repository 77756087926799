/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Catalog Header
// ==============================================

.catalog-header {
    text-align: center;
}

.catalog-header_image {
    margin-bottom: $gap;
}

.catalog-header_image img {
    max-width: 100%;
    border-radius: $border-radius;
}

.catalog-header_description {
    margin-bottom: $gap;
    font-size: 20px;
    line-height: 1.1em;
}
