/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Header - Search
// ==============================================

@media (max-width: $bp-header - 1px) {

    .header-search {
        display: none; // Hide initially
        padding: 20px $liner;
    }

}


// ==============================================
// Search Form
// ==============================================

.search-form {
    position: relative;
}

//.search-form_liner {}

.search-form_label {
    display: none;
}

.search-form_input {
    width: 100%;
    max-width: none; // Override ".input-text"
    height: $m-header-trigger-height;
    padding: 0 45px 0 10px;
    border-right: 0;
}

.search-form_button {
    @include image-replacement;
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: $m-header-trigger-height;
    padding: 0;
    border: 0;
    border-radius: 0 3px 3px 0;
    background: $c-button-cta;

    &:before {
        @include svg (search);
        content: '';
        position: absolute;
        top: (($m-header-trigger-height - 24px) / 2);
        left: 50%;
        width: 24px;
        height: 24px;
        margin-left: (24px / -2);
    }

    &:hover {
        background: $c-button-cta-hover;
    }

    &:focus {
        @include focus;
        background: $c-button-cta-focus;
    }
}

// ----------------------------------------------

@media (min-width: $bp-header) {

    .search-form {
        height: ($m-header-bar-height - 5px - 5px);
    }

}


// ==============================================
// Search Typeahead
// ==============================================

@media (max-width: $bp-header - 1px) {

    .search-typeahead {
        margin-top: 20px;
        background: #FFF;

        &:after {
            border-bottom-color: #FFF; // CSS arrow background
        }
    }

}

// ----------------------------------------------

@media (min-width: $bp-header) {

    .search-typeahead {
        @include menu;
        top: ($m-header-trigger-height + 15px);
        width: 500px;
        border: 0;
    }

}
