/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Page Title
// ==============================================

//.page-title {}

.page-title h1 {
    font-size: 28px;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .page-title h1 {
        font-size: 32px;
    }

}


// ==============================================
// Page Title - Pre
// ==============================================

.page-title_pre {
    font-size: $f-size-xs;
}


// ==============================================
// Page Title - Actions
// ==============================================

.page-title_actions {
    float: right;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
}
