/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Toggle UI
// ==============================================

[data-toggle-trigger] {
    cursor: pointer;
}

[data-toggle-target] {
    display: none;
}

[data-toggle='show'] [data-toggle-target] {
    display: block;
}
