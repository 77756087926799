/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Matter
// ==============================================

.site-matter {
    padding-top: $trim-mid;
}

.catalog-category-view .site-matter,
.catalogsearch-result-index .site-matter {
    padding-top: $trim;
}

// ----------------------------------------------

@media (min-width: 900px) {

    .site-matter {
        padding-top: $trim-wide;
    }

}
