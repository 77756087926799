/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Payment Details List
// ==============================================

//.payment-details-list {}

//.payment-details-list dt {}

.payment-details-list dd {
    padding-bottom: 10px;
}
