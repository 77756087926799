/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Logo
// ==============================================

.site-logo {
    display: block;

    &:hover {
        opacity: 0.75;
    }
}

.site-logo h1 {
    margin: 0;
}

// ----------------------------------------------

@media (max-width: $bp-header - 1px) {

    .site-logo {
        padding: 10px;
    }

    .site-logo img {
        height: 40px;
        margin: 0 auto;
    }

}

// ----------------------------------------------

@media (min-width: $bp-header) {

    .site-logo {
        position: relative;
        left: -15px;
        width: (15px + 192px + 15px);
        padding: 25px 15px;
    }

}
