/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Default
// ==============================================

$border-radius: 3px;

$box-shadow:             0 2px 8px black(0.15);
$box-shadow-inset: inset 0 2px 8px black(0.15);

$transition: all 200ms linear 0;
