/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// **********************************************
// Shame:
// Workarounds for specific browsers / devices.
// **********************************************


// ==============================================
// Enable click events to bubble to the "document" in iOS Safari.
// See: /skin/.../js/src/app.js
// ==============================================

.is-ios * {
    cursor: pointer;
}
