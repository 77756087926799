/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Heading Group
// ==============================================

.heading-group {
    display: flex;
    align-items: flex-start;
}

.heading-group .button {
    margin-left: 2em;
}
