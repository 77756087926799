/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Inline Frame
// ==============================================

.iframe-wrapper {
    margin-bottom: $gap;
}

.iframe-wrapper iframe {
    width: 100%;
    height: 650px;
    border: 0;
}
