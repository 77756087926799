/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Stat
// ==============================================

.stat {
    margin-bottom: $margin-bottom;
}

.stat_label {
    font-size: $f-size-xxs;
    font-weight: bold;
    text-transform: uppercase;
}

//.stat_value {}


// ==============================================
// Modifier: Strong
// ==============================================

.stat--strong .stat_label {
    font-weight: normal;
}

.stat--strong .stat_value {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
}
