/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Limiter
// ==============================================

.limiter {
    display: inline-block;
}

.limiter label {
    display: none;
}
