/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Header Bar
// ==============================================

@media (min-width: $bp-header) {

    .header-bar {
        height: $m-header-bar-height;
        border-radius: $border-radius;
        background: $c-header-bar-background;
    }

}
