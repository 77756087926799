/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Contact Grid
// ==============================================

@media (min-width: 900px) {

    .contact-grid {
        &:after {
            @include clearfix;
        }
    }

    .contact-grid .col-1 {
        float: left;
        width: calc(100% - 360px);
        padding-right: ($gap * 2);
    }

    .contact-grid .col-2 {
        float: left;
        width: 360px; // Calc fixed value
        padding-right: 40px;
    }

}
