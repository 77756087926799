/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Featured Product
// ==============================================

.featured-product {
    margin-bottom: $gap;
}

.featured-product .product-name {
    margin-bottom: 1em;
}
