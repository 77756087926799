/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Masthead
// ==============================================

.masthead {
    margin-bottom: $gap;
    text-align: center;
}

.masthead-title {
    margin-bottom: 0.4em;
    color: $c-text;
    font-family: $f-stack-special;
    font-size: 22px;
    font-weight: bold;
}

.masthead .lead {
    max-width: 35em;
    margin-right: auto;
    margin-left: auto;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .masthead-title {
        font-size: 28px;
    }

}

// ----------------------------------------------

@media (min-width: 1000px) {

    .masthead-title {
        font-size: 36px;
    }

}
