/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Sales - Order Info
// ==============================================

.payment-details-list dt {
    font-style: italic;
}

.gift-message-history {
    margin-bottom: $gap;
}

//.order-products-table {}

//.order-totals-table {}

.order-totals-table td:first-child {
    text-align: left;
}
