/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Remove Button
// ==============================================

.remove-button {
    @include image-replacement;
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: (24px / 2);
    background: $c-button-subtle;
    color: #FFF;
    line-height: 24px;

    &:hover {
        background: $c-danger;
    }

    &:before {
        content: '';
        @include svg (remove-button);
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;

    }
}
