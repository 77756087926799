/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Pages
// ==============================================

.pages {
    margin-bottom: 10px;

    &:after {
        @include clearfix;
    }
}

.pages li {
    float: left;
    margin: 0 8px 8px 0;
    font-size: $f-size-s;
    text-align: center;
}

.pages a,
.pages .current {
    @extend .button;
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
}

.pages a {
    text-decoration: none;
}


// ----------------------------------------------
// Current

.pages .current {
    background: #FFF;
    box-shadow: inset 0 0 0 1px $c-slate;
    color: $c-text;

    &:hover {
        background: transparent;
        color: $c-text;
        cursor: default;
    }
}


// ----------------------------------------------
// Next + Previous

.pages .next,
.pages .previous {
    width: auto;
    padding: 0 10px;
}
