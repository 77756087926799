/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Header Menu - Unit
// ==============================================

.header_menu_unit {
    padding: 15px $liner;
    border-bottom: 1px solid $c-module-border;

    &:last-child {
        border-bottom: 0;
    }
}

.header_menu_unit h1 {
    @include h3;
}

.header_menu_unit p {
    &:last-child {
        margin-bottom: 0;
    }
}


// ==============================================
// Header Menu - Header
// ==============================================

/*
.header_menu_header {
    padding: 12px $liner;
}

.header_menu_header h1,
.header_menu_header h2 {
    @include h3;
    margin-bottom: 0;
}
*/


// ==============================================
// Header Menu - List
// ==============================================

.header_menu_list {
    padding-top: 5px;
    //border-top: 1px solid $c-module-border;
}

.header_menu_list li {
    border-bottom: 1px solid $c-module-border;
}

.header_menu_list a {
    display: block;
    padding: 12px $liner;
    color: $c-text;
    line-height: 1.3;
}

.no-touchevents .header_menu_list a:hover {
    background: $c-module-border;
    color: $c-text;
}


// ==============================================
// Header Menu - Actions
// ==============================================

.header_menu_actions {
    padding: 20px $liner;
}

.header_menu_actions--center {
    text-align: center;
}
